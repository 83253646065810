import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import PostJobForm from '../components/post-job-form'

import { get } from 'lodash-es'

export default ({ data }) => {
    let page = get(data, 'allWordpressPage.edges[0].node')

    return (
        <Layout>
            <PostJobForm />
            <SEO
                title={get(page, 'yoast_meta.yoast_wpseo_title')}
                description={get(page, 'yoast_meta.yoast_wpseo_metadesc')}
            />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "post-job" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
