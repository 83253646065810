import React from 'react'

import Form from '../form'

import Input from '../input'

import styles from './style.module.scss'

import Button from '../button'

import CVImage from '../../images/job.svg'

import PhoneInput from '../phone-input'

import { addJob } from '../form/wpActions'

export default () => {
    return (
        <div className={styles.postEnquiryForm}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <h1>Post Job</h1>
                        <Form
                            className={styles.form}
                            onSubmit={async data => {
                                await addJob(data)
                            }}
                            requiredFields={[
                                'company',
                                'email',
                                'location',
                                'address',
                                'job_title',
                                'salary',
                                'minimum_experience',
                                'job_description'
                            ]}
                            errorMessageClass={styles.errorMessage}
                        >
                            <p>
                                Your next hire is here. We help thousands of
                                real estate job seekers and employers find the
                                right fit every day. Start hiring now on the
                                world's #1 real estate portal.
                            </p>

                            <Input
                                type="text"
                                name="company"
                                label="Company"
                                required
                            />

                            <Input
                                type="text"
                                name="email"
                                label="Company email"
                                required
                            />

                            <PhoneInput />

                            <Input
                                type="text"
                                name="address"
                                label="Address"
                                placeholder="Your company address"
                                required
                            />

                            <Input
                                type="text"
                                name="job_title"
                                label="Job title"
                                placeholder="Available job title"
                                required
                            />
                            <Input
                                type="text"
                                name="salary"
                                label="Salary"
                                required
                            />
                            <Input
                                type="text"
                                name="minimum_experience"
                                label="Minimum experience"
                                required
                            />

                            <Input
                                type="textarea"
                                name="job_description"
                                label="Job description"
                                required
                                rows={10}
                            />

                            <Button>Submit</Button>
                        </Form>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <CVImage className={styles.question} />
                    </div>
                </div>
            </div>
        </div>
    )
}
