import axios from 'axios'

const instance = axios.create({
    baseURL: `${process.env.GATSBY_PROTOCOL}://${process.env.GATSBY_BASE_URL}/wp-admin/admin-ajax.php`
})

export const doAction = async data => {
    const formData = new FormData()

    for (const key of Object.keys(data)) {
        formData.append(key, data[key])
    }

    const response = await instance.post('', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })

    return response
}

export const addCv = async data => {
    return await doAction({ action: 'add_cv', ...data })
}

export const addJob = async data => {
    return await doAction({ action: 'add_job', ...data })
}
